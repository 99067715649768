import React, { useEffect } from "react"

import { useIntl, Link } from "gatsby-plugin-intl"

import Arrow from "../assets/images/icons/button-arrow.svg"
import ComputerForex from "../assets/images/MacBookPro-inicio.png"
import Marcas from "../assets/images/marcas.png"
import Layout from "../components/Layout.jsx"
import Seo from "../components/Seo"

export default function Index() {
  const intl = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Seo title="Inicio" />
      <main className="home">
        <section className="home__hero">
          <div className="home__hero-container">
            <div className="home__hero-text">
              <h1 className="home__hero-title">
                {intl.formatMessage({ id: "home-hero-title" })}
              </h1>
              <p className="home__hero-paragraph">
                {intl.formatMessage({ id: "home-hero-text" })}
              </p>
            </div>
            <span className="home__hero-actions">
              <Link to="/market" className="home__hero-button">
                <p>{intl.formatMessage({ id: "learn-more" })}</p>
                <img src={Arrow} alt="Arrow" className="home__hero-icon" />
              </Link>
              {/* <a
                href={config.signin}
                target="_blank"
                rel="noopener noreferrer"
                className="home__hero-button"
              >
                <p>{intl.formatMessage({ id: "start-trading" })}</p>
                <img src={Arrow} alt="Arrow" className="home__hero-icon" />
              </a> */}
            </span>
          </div>
        </section>
        <section className="home__forex">
          <div className="home__forex-container">
            <div className="home__forex-box">
              <h3 className="home__forex-title">
                {intl.formatMessage({ id: "home-forex-title" })}
              </h3>
              <p className="home__forex-text">
                {intl.formatMessage({ id: "home-forex-text" })}
              </p>
            </div>
            <div className="home__forex-box">
              <img
                src={ComputerForex}
                alt="Forex"
                className="home__forex-image"
              />
            </div>
          </div>
        </section>
        <section className="home__tools">
          <div className="home__tools-container">
            <h2 className="home__tools-title">
              {intl.formatMessage({ id: "home-tools-title" })}
            </h2>
            <p className="home__tools-text">
              {intl.formatMessage({ id: "home-tools-text" })}
            </p>
          </div>
        </section>
        <section className="home__market">
          <div className="home__market-container">
            <div className="home__market-box home__market-box--image">
              <img src={Marcas} alt="" className="home__market-image" />
            </div>
            <div className="home__market-box">
              <h2 className="home__market-title">
                {intl.formatMessage({ id: "home-market-title" })}
              </h2>
              <ul className="home__market-list">
                <li className="home__market-item">
                  {intl.formatMessage({ id: "home-market-list-one" })}
                </li>
                <li className="home__market-item">
                  {intl.formatMessage({ id: "home-market-list-five" })}
                </li>
                <li className="home__market-item">
                  {intl.formatMessage({ id: "home-market-list-two" })}
                </li>
                <li className="home__market-item">
                  {intl.formatMessage({ id: "home-market-list-three" })}
                </li>
                <li className="home__market-item">
                  {intl.formatMessage({ id: "home-market-list-four" })}
                </li>
                <li className="home__market-item">
                  {intl.formatMessage({ id: "home-market-list-six" })}
                </li>
                <li className="home__market-item">
                  {intl.formatMessage({ id: "home-market-list-seven" })}
                </li>
              </ul>
              <Link to="/market" className="home__market-button">
                <p>{intl.formatMessage({ id: "learn-more" })}</p>
                <img src={Arrow} alt="Arrow" className="home__hero-icon" />
              </Link>
            </div>
          </div>
          {/* <div className="market__advanced">
            <h2 className="market__advanced-title"></h2>
          </div> */}
        </section>
        <section className="home__why">
          <div className="home__why-container">
            <h2 className="home__why-title">
              {intl.formatMessage({ id: "home-why-title" })}
            </h2>
            <div className="home__why-grid">
              <article className="home__why-item">
                <h2 className="home__why-name">
                  {intl.formatMessage({ id: "home-why-name-one" })}
                </h2>
                <p className="home__why-text">
                  {intl.formatMessage({ id: "home-why-text-one" })}
                </p>
              </article>
              <article className="home__why-item">
                <h2 className="home__why-name">
                  {intl.formatMessage({ id: "home-why-name-two" })}
                </h2>
                <p className="home__why-text">
                  {intl.formatMessage({ id: "home-why-text-two" })}
                </p>
              </article>
              <article className="home__why-item">
                <h2 className="home__why-name">
                  {intl.formatMessage({ id: "home-why-name-three" })}
                </h2>
                <p className="home__why-text">
                  {intl.formatMessage({ id: "home-why-text-three" })}
                </p>
              </article>
              <article className="home__why-item">
                <h2 className="home__why-name">
                  {intl.formatMessage({ id: "home-why-name-four" })}
                </h2>
                <p className="home__why-text">
                  {intl.formatMessage({ id: "home-why-text-four" })}
                </p>
              </article>
            </div>
          </div>
        </section>
        <section className="home__steps">
          <div className="home__steps-container">
            <div className="home__steps-grid">
              <div className="home__steps-box">
                <ul className="home__steps-list">
                  <li className="home__steps-item">
                    <span className="home__steps-number">1</span>
                    <h3 className="home__steps-name">
                      {intl.formatMessage({ id: "home-steps-title-one" })}
                    </h3>
                    <p className="home__steps-text">
                      {intl.formatMessage({ id: "home-steps-text-one" })}
                    </p>
                  </li>
                  <li className="home__steps-item">
                    <span className="home__steps-number">2</span>
                    <h3 className="home__steps-name">
                      {intl.formatMessage({ id: "home-steps-title-two" })}
                    </h3>
                    <p className="home__steps-text">
                      {intl.formatMessage({ id: "home-steps-text-two" })}
                    </p>
                  </li>
                  <li className="home__steps-item">
                    <span className="home__steps-number">3</span>
                    <h3 className="home__steps-name">
                      {intl.formatMessage({ id: "home-steps-title-three" })}
                    </h3>
                    <p className="home__steps-text">
                      {intl.formatMessage({ id: "home-steps-text-three" })}
                    </p>
                  </li>
                  <li className="home__steps-item">
                    <span className="home__steps-number">4</span>
                    <h3 className="home__steps-name">
                      {intl.formatMessage({ id: "home-steps-title-four" })}
                    </h3>
                    <p className="home__steps-text">
                      {intl.formatMessage({ id: "home-steps-text-four" })}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="home__steps-box home__steps-box--button">
                <Link to="/market" className="home__steps-button">
                  <p>{intl.formatMessage({ id: "learn-more" })}</p>
                  <img src={Arrow} alt="Arrow" className="home__hero-icon" />
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}
